/* eslint-disable no-console,no-undef,camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  commaSeperator,
  convertDateInTimezone,
  convertMonthFromDate,
  // getPermissionStatus,
  getStatus,
  getTimeToShow,
  getUnit,
} from "../../../utils/methods";
import moment from 'moment';
import { connect } from "react-redux";
import Image from '../../Image';
import isEmpty from 'lodash/isEmpty';
import Waiting from '../../Waiting';
import { getChallengeDownloadCSVStatus, joinOrLeaveChallenge, getGroupChallengeCsvData, getInviteAmigosList, getUserListOfChallenges, getBuddiesListOfChallenges } from "../../../redux/actions/challengeActions";
import CSVList from "../../AttendeesModal/CSVList";
import InviteAmigoPopUp from "../InviteAmigoPopUp";
const ParticipantsPopUp = React.lazy(() => import('../participantsInChallengePopUp/index'));
import { MyTaskContainerV2, /*ButtonWrapper, PrintButton*/ } from '../MyTaskTab/styles';
import { Border } from '../../EventDetails/styles';
import { EventDateContainerNew, WeeklyStreak } from './styles';
import { withTranslation } from 'react-i18next';
import LazyImage from '../../common/LazyImage/LazyImage';
import { ImageUrl } from '../../../utils/constants';
import { ChallengeDateContainer, ChallengePointContainer } from '../styles';

let csvHeaders = [];
class SurvivorOverViewScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: [],
      isChallengeLocked: getStatus(props.challenge),
      isUpdatedImage: false,
      printChallengeCSV: false,
      updatedChallengeCSV: {},
      showInviteAmigoPopup: false,
      showParticipants: false,
    }
  }
  componentDidMount() {
    const { location: { pathname }, challenge, fetchTeamChallenge, getUserOverviewDetails, getChallengeCsvData, getChallengeDownloadCSVStatus, getGroupChallengeCsvData, getBuddiesListOfChallenges } = this.props;
    const StringArray = pathname.split('/');
    getChallengeDownloadCSVStatus(challenge.id);
    // getUserListOfChallenges(challenge.id);
    getBuddiesListOfChallenges(challenge.id, 1);
    if (challenge.challenge_type === 'new_group') {
      getGroupChallengeCsvData(challenge.id, challenge.event_type);
    } else {
      getChallengeCsvData(challenge.id, challenge.event_type, 'all');
    }
    getUserOverviewDetails(challenge.id, challenge.event_type, this.props.userId);
    if (challenge.challenge_type === 'survivor' && challenge.challenge_mode == 'Team') {
      fetchTeamChallenge(StringArray[(StringArray.length - 1)], challenge.event_type);
    }
    this.checkBase64(this.props.profileImage);
    this.interval = setInterval(() => {
      this.setState({
        time: getTimeToShow(challenge, 'challenge')
      })
    }, 1000);

  }
  joinChallenge = (challengeId, isJoined) => {
    const { joinOrLeaveChallenge } = this.props;
    const data = {
      challenge_id: challengeId,
      is_joined: isJoined
    };
    joinOrLeaveChallenge(data);
  };
  checkBase64(profileImage) {
    if (profileImage) {
      let arr = profileImage.split('/');
      if (arr[0] !== 'profile-Image') {
        this.setState({
          isUpdatedImage: true
        })
      } else {
        this.setState({
          isUpdatedImage: false
        })
      }
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.challenge !== this.props.challenge) {
      clearInterval(this.interval);
      this.interval = setInterval(() => {
        this.setState({
          time: getTimeToShow(this.props.challenge, 'challenge')
        })
      }, 1000);
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.profileImage != nextProps.profileImage) {
      this.checkBase64(nextProps.profileImage)
    }
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  showProfileImage = () => {
    const { profileImage } = this.props;
    const { isUpdatedImage } = this.state;
    if (isUpdatedImage) {
      return (<img src={profileImage} alt="avatar" />)
    }
    else {
      return (<Image image={profileImage} alt="avatar" />)
    }

  };

  printAction = (bool) => {
    const { challengeCsvData, challenge } = this.props;
    const ChallengeType = challenge && challenge['event_type'].split(' ')[1];
    let updatedChallengeCSV = challengeCsvData && challengeCsvData.length > 0 && challenge.challenge_type === 'new_group' ?
      challengeCsvData.map((competitor) => {
        let obj = {};
        obj['FIRST NAME'] = competitor['fname'];
        obj['LAST NAME'] = competitor['lname'];
        obj['RANK'] = competitor['user_rank'];
        obj['EMAIL'] = competitor['EMAIL'];
        obj[`Total ${ChallengeType.charAt(0).toUpperCase()}${ChallengeType.slice(1)}`] = _.isNull(competitor[ChallengeType.toLowerCase()]) ? 0 : ChallengeType === 'Distance' ? `${getUnit(competitor[`${ChallengeType.toLowerCase()}`])} ${'miles'}` : commaSeperator(parseInt(competitor[`${ChallengeType.toLowerCase()}`],10));
        return obj;
      })
      : challengeCsvData.map((competitor, index) => {
        let obj = {};
        obj['FIRST NAME'] = competitor['fname'];
        obj['LAST NAME'] = competitor['lname'];
        obj['RANK'] = index + 1;
        obj[`Total ${ChallengeType.charAt(0).toUpperCase()}${ChallengeType.slice(1)}`] = _.isNull(competitor[ChallengeType.toLowerCase()]) ? 0 : ChallengeType === 'Distance' ? `${getUnit(competitor[`${ChallengeType.toLowerCase()}`])} ${'miles'}` : commaSeperator(parseInt(competitor[`${ChallengeType.toLowerCase()}`],10));
        obj['Daily Average'] = challenge['challenge_type'] === 'group' ? competitor['daily_average'] : competitor['challenge_average'];
        obj['Weekly Average'] = _.isNull(competitor[ChallengeType.toLowerCase()]) ? 0 : commaSeperator(Math.round(competitor['weekly_average']));
        obj['No of Weeks Survived'] = challenge && challenge['challenge_type'] === 'survivor' && challenge['challenge_mode'] === 'Solo' && (!_.isNull(competitor['weekly_marks']) || !_.isUndefined(competitor['weekly_marks'])) ? competitor['weekly_marks'].filter((item) => item !== 0).length : 0;
        obj['EMAIL'] = competitor['EMAIL'];
        obj['company_name'] = competitor['company_name'];
        obj['location'] = competitor['location'];
        return obj;
      });
    this.setState({ printChallengeCSV: bool, updatedChallengeCSV: updatedChallengeCSV })
  };

  getCSVHeaders = () => {
    const { challenge } = this.props;
    const ActivityType = challenge && challenge.event_type.split(' ')[1];
    const challengeType = challenge && challenge.challenge_type;
    if (challengeType === 'new_group') {
      csvHeaders = [
        { label: "First Name", key: "FIRST NAME" },
        { label: "Last Name", key: "LAST NAME" },
        { label: "Email", key: "EMAIL" },
        { label: "Rank", key: "RANK" },
      ];
    } else {
      csvHeaders = [
        { label: "First Name", key: "FIRST NAME" },
        { label: "Last Name", key: "LAST NAME" },
        { label: "Email", key: "EMAIL" },
        { label: "Location", key: "location" },
        { label: "Company Name", key: "company_name" },
        { label: "Rank", key: "RANK" },
        { label: "Daily Average", key: "Daily Average" },
        { label: "Weekly Average", key: "Weekly Average" },
      ];
    }
    csvHeaders.push({ label: `Total ${ActivityType}`, key: `Total ${ActivityType}` });
    challengeType === 'survivor' && challenge.challenge_mode === 'Solo' && csvHeaders.push({ label: "No of Weeks Survived", key: `No of Weeks Survived` });
    return csvHeaders;
  };
  showHideInviteAmigoPopup = () => {
    this.setState((prevState) => ({
      showInviteAmigoPopup: !prevState.showInviteAmigoPopup
    }), () => this.props.getInviteAmigosList(this.props.userId, this.props.challenge.id))
  };
  changeParticipantsModal = () => {
    this.setState((prevState) => ({
      showParticipants: !prevState.showParticipants
    }));
  }

  render() {
    const { challenge, userId, /*challengeCsvData,*/ challengeCSVStatus, /*userPermissions,*/ challengeAttendees, amigosList, userChallengeDetails, t, /*role*/ } = this.props;
    const { /*printChallengeCSV,*/ updatedChallengeCSV, showParticipants, printChallengeCSV } = this.state;
    if ((!(userChallengeDetails) || !userId || isEmpty(challengeCSVStatus))) {
      return <Waiting />
    }
    const fileTitle = challenge && challenge.title.replace(/ /g, "_");
    let propsChallengeCompetitors = [];
    let challengeCompetitors = {};
    propsChallengeCompetitors = userChallengeDetails['user_details'];
    const valid = convertDateInTimezone(challenge.end_date)._d >= moment()._d;
    if (challenge.challenge_status !== 'over' || valid) {
      challengeCompetitors = propsChallengeCompetitors;
    }
    else if (userChallengeDetails) {
      challengeCompetitors = propsChallengeCompetitors;
    }
    let arr = [];
    for (let i = 0; i < challenge.weeks+1; i++) {
      arr.push(i);
    }
    if (_.isUndefined(challengeCompetitors) || !userChallengeDetails || Object.keys(userChallengeDetails).length === 0) {
      return <Waiting />
    }
    let today = moment()._d;
    let startDay = convertDateInTimezone(challenge.start_date)._d;
    let lang = localStorage.getItem("lang");

    return (
      <MyTaskContainerV2 margin={"1"}>
        {/* <ButtonWrapper>
          <div className="firstButton" />
          <div className="lastButton">
            {(!isEmpty(challengeCSVStatus) && (challenge.is_global_challenge && role === "ADMIN" || getPermissionStatus("Download challenge CSV", userPermissions)) ) && (challenge.challenge_status == 'over' || !valid) && (challengeCsvData && challengeCsvData.length > 0) ? <PrintButton onClick={() => this.printAction(true)} disabled={challengeCsvData.length <= 0 || printChallengeCSV}>
              <div><img src="/public/images/NewDashboardV2/downloadCSV.png" alt="back-arrow" /></div>
              <div >
                <span>{t("Download Challenge CSV")}</span>
              </div>
            </PrintButton> : null}
          </div>
        </ButtonWrapper> */}
        <ChallengeDateContainer color={"#005C87"} cursor={"1"}>
          <LazyImage src={ImageUrl + "/ChallengeDetailsScreen/calendarImage.png"} alt="back-arrow"/>
          <div>
            <div>
              <div className="attendies">{t("Start Date")}
              </div>
              <div>{ (lang != "fr") ? convertMonthFromDate(moment(convertDateInTimezone(challenge.start_date)._d).format('MMM Do, YYYY'), this.props) : convertMonthFromDate(moment(convertDateInTimezone(challenge.start_date)._d).format('D MMM YYYY'), this.props)}
              </div>
            </div>
            <Border background="#005C874D"/>
            <div>
              <div>{t("End Date")}</div>
              <div>{ (lang != "fr") ? convertMonthFromDate(moment(convertDateInTimezone(challenge.end_date)._d).format('MMM Do, YYYY'), this.props) : convertMonthFromDate(moment(convertDateInTimezone(challenge.end_date)._d).format('D MMM YYYY'), this.props)}</div>
            </div>
          </div>
        </ChallengeDateContainer>
        {(challenge.challenge_status === 'joined') && !(today < startDay) ? <EventDateContainerNew>
          {challenge.challenge_status !== 'over' ?<WeeklyStreak>
            {<div>{t("Weekly Streak")}</div>}
            {_.isNull(challengeCompetitors) || _.isNull(challengeCompetitors['weekly_details'])?null: <div>{arr.map((week, index) =>
              ( !_.isUndefined(challengeCompetitors['weekly_details']) && challengeCompetitors['weekly_details'].length - 1 >= index ?
                (challengeCompetitors['weekly_details'][index]['weekly_avg'] >= challengeCompetitors['weekly_details'][index]['weekly_goal'] ? <img src={ImageUrl+"/ChallengeDetailsScreen/completeStar.png"} alt="complete" key={index} /> : <img src={ImageUrl+"/ChallengeDetailsScreen/incompleteStar.png"} alt="Incomplete" key={index} />) :
                <img src={ImageUrl+"/ChallengeDetailsScreen/notStartStar.png"} alt="streak" key={index} />
              ))}
            </div>}
          </WeeklyStreak>:<div />}
          {challenge.challenge_status !== 'over'?<Border margin="auto 15px"/>:<div />}
          <div>
            <div>{t("Point Value")}</div>
            <div>+{challenge.challenge_point}{t("pts")}</div>
          </div>
          <Border margin="auto 15px"/>
          <div>
            <div>{t("Location")}</div>
            <div>{t(challenge.challenge_location)}</div>
          </div>
        </EventDateContainerNew> :
          <ChallengePointContainer>
            <div>
              <div>{t("Point Value")}</div>
              <div>+{challenge.challenge_point}{t("pts")}</div>
            </div>
            <Border margin="auto 15px" background="#005C874D"/>
            <div>
              <div>{t("Location")}</div>
              <div>{t(challenge.challenge_location)}</div>
            </div>
          </ChallengePointContainer>}
        <ParticipantsPopUp
          showModal={showParticipants}
          onClose={this.changeParticipantsModal}
          challengeAttendees={challengeAttendees}
          amigosList={amigosList}
        />
        {printChallengeCSV && <CSVList Title={`${fileTitle}_${challenge && challenge.id}`} removeCSVData={this.printAction} headers={this.getCSVHeaders()} data={updatedChallengeCSV} />}
        <InviteAmigoPopUp userId={userId} history={this.props.history} showModal={this.state.showInviteAmigoPopup} onHide={this.showHideInviteAmigoPopup} challengeId={challenge.id} plusAmigoButton={1} />
      </MyTaskContainerV2>
    )
  }
}
SurvivorOverViewScreen.propTypes = {
  getUserOverviewDetails: PropTypes.func.isRequired,
  userChallengeDetails: PropTypes.object,
  challenge: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  userId: PropTypes.number.isRequired,
  profileImage: PropTypes.string.isRequired,
  fetchTeamChallenge: PropTypes.func.isRequired,
  teamChallenge: PropTypes.array,
  joinOrLeaveChallenge: PropTypes.func,
  getChallengeCsvData: PropTypes.func,
  challengeCsvData: PropTypes.array,
  getChallengeDownloadCSVStatus: PropTypes.func,
  challengeCSVStatus: PropTypes.object,
  isDummyChallenge: PropTypes.bool,
  getGroupChallengeCsvData: PropTypes.func,
  userPermissions: PropTypes.array,
  history: PropTypes.object,
  getInviteAmigosList: PropTypes.func,
  challengeAttendees: PropTypes.array,
  getUserListOfChallenges: PropTypes.func,
  getBuddiesListOfChallenges: PropTypes.func,
  amigosList: PropTypes.array,
  t: PropTypes.func,
  role: PropTypes.string,
  updateDownloadCSV: PropTypes.func
};
const mapStateToProps = (state) => ({
  userChallengeDetails: state.challenges.userChallengeDetails,
  teamChallenge: state.challenges.teamChallenge,
  challengeCsvData: state.challenges.challengeCsvData,
  challengeCSVStatus: state.challenges.challengeCSVStatus,
  isDummyChallenge: state.challenges.isDummyChallenge,
  challengeAttendees: state.challenges.challengeAttendees,
  amigosList: state.challenges.amigosList,
});
const mapDispatchToProps = (dispatch) => ({
  joinOrLeaveChallenge: (data) => dispatch(joinOrLeaveChallenge(data)),
  getChallengeDownloadCSVStatus: (challengeId) => dispatch(getChallengeDownloadCSVStatus(challengeId)),
  getGroupChallengeCsvData: (challengeId, challengeType) => dispatch(getGroupChallengeCsvData(challengeId, challengeType)),
  getInviteAmigosList: (userID, challengeId) => dispatch(getInviteAmigosList(userID, challengeId)),
  getUserListOfChallenges: (challengeId) => dispatch(getUserListOfChallenges(challengeId)),
  getBuddiesListOfChallenges: (challengeId, status) => dispatch(getBuddiesListOfChallenges(challengeId, status)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SurvivorOverViewScreen));
